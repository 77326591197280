import React from "react";
import "./monthlybulletin.css";
//import imgfr from "../../assets/pics/frandre.png";
import { useTransition, useTranslation } from "react-i18next";
//import En_Jan25 from "../../assets/pdffiles/report/En_Jan_25_Monthly_Bulletin.pdf";
//import Fr_Jan25 from "../../assets/pdffiles/report/Fr_Jan_25_Monthly_Bulletin.pdf";
import { Container } from "react-bootstrap";

function monthlyNews() {
  const { t, i18n } = useTranslation();

  const News_Bulletin = t('Downloads_News_Bulletin', {
    returnObjects: true
  });
  return (
    <div>
      <h4 className="headingText">{t("News")}</h4>

     <Container>
      <ol className="Higher_Monthlynewsbulletin"  >
      <li className="Monthly_News_Bulletin">
        <a href={t('Downloads_Report_Jan_25.download')} target="_blank" rel="noopener noreferrer"
        > {t('Downloads_Report_Jan_25.title')}</a>
      </li>
    
      </ol>
      </Container>
    </div>

  );
  
}
export default monthlyNews;